import React, { useState } from 'react'

/* eslint-disable */

const ContactPicker = () => {
  const [contact, setContact] = useState(null)
  const [error, setError] = useState(null)

  const handlePickContact = async () => {
    if ('contacts' in navigator && 'ContactsManager' in window) {
      try {
        const props = ['name', 'email', 'tel']
        const opts = { multiple: false }
        const contacts = await navigator.contacts.select(props, opts)
        if (contacts.length > 0) {
          const selectedContact = contacts[0]
          setContact({
            name: selectedContact.name ? selectedContact.name.join(', ') : 'Not available',
            email: selectedContact.email ? selectedContact.email.join(', ') : 'Not available',
            phone: selectedContact.tel ? selectedContact.tel.join(', ') : 'Not available',
          })
        }
      } catch (err) {
        console.error('Error picking contact:', err)
        setError('An error occurred while picking a contact.')
      }
    } else {
      setError('Contact Picker API is not supported by your browser.')
    }
  }

  return (
    <div>
      <h1>Contact Picker API Demo</h1>
      <button onClick={handlePickContact}>Pick a Contact</button>

      {contact && (
        <div>
          <h2>Selected Contact:</h2>
          <p><strong>Name:</strong> {contact.name}</p>
          <p><strong>Email:</strong> {contact.email}</p>
          <p><strong>Phone:</strong> {contact.phone}</p>
        </div>
      )}

      {error && (
        <div style={{ color: 'red' }}>
          <p>{error}</p>
        </div>
      )}
    </div>
  )
}

/* eslint-enable */

export default ContactPicker
